import ClientCompany from '../types/ClientCompany';
import client from '../../api/client';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';

type CreateClientCompanyParams = {
  name: string;
  subsidiaryCompanyId: SubsidiaryCompany['id'];
};

type UpdateClientCompanyParams = {
  name: string;
  elsaClientCompanyId?: string;
};

export type ClientCompanyFilterParams = {
  subsidiaryCompanyIds?: SubsidiaryCompany['id'][];
};

const getClientCompanies = (
  clientCompanyFilterParams?: ClientCompanyFilterParams,
) =>
  client.get<ClientCompany[]>(`/client-companies/`, {
    params: clientCompanyFilterParams,
  });

const getClientCompany = (id: ClientCompany['id']) =>
  client.get<ClientCompany>(`/client-companies/${id}`);

const createClientCompany = (params: CreateClientCompanyParams) =>
  client.post<ClientCompany>('/client-companies', params);

const updateClientCompany = (
  id: ClientCompany['id'],
  params: UpdateClientCompanyParams,
) => client.patch<ClientCompany>(`/client-companies/${id}`, params);

const deleteClientCompany = (id: ClientCompany['id']) =>
  client.delete<ClientCompany>(`/client-companies/${id}`);

const deleteBookingOrderClientCompany = (
  clientCompanyId: number,
  editedBookingOrderId: number | null,
  replacementClientCompanyId: number,
) =>
  client.delete<ClientCompany>(
    `/client-companies/booking-order/${editedBookingOrderId}/client-company/${clientCompanyId}/replacement/${replacementClientCompanyId}`,
  );

const deleteBookingOrderClientCompanyDryRun = (
  clientCompanyId: number,
  editedBookingOrderId: number | null,
) =>
  client.delete<ClientCompany>(
    `/client-companies/booking-order/${editedBookingOrderId}/client-company/${clientCompanyId}/dry-run`,
  );

export default {
  getClientCompanies,
  getClientCompany,
  createClientCompany,
  updateClientCompany,
  deleteClientCompany,
  deleteBookingOrderClientCompany,
  deleteBookingOrderClientCompanyDryRun,
};
